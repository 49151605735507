import { formatCurrencyWithUnit } from '@dtk/formatter';
import { ChartTrend } from './utils';

export interface TooltipProps {
  trend: ChartTrend;
  street: string | undefined;
}

export const Tooltip = ({ trend, street }: TooltipProps) => {
  return (
    <div className="border-rounded p-1">
      <p className="pb-1 text-sm font-bold">Ø {formatCurrencyWithUnit(trend.totalValuePerQM, 0, 'm', 2)}</p>
      {trend.relative && (
        <p className="pb-1 text-xs leading-4">
          <span className="font-bold">{trend.relative}%</span> zu {trend.year.getFullYear() - 1}
        </p>
      )}
      {street && (
        <ul className="text-gold-sand list-disc pl-4">
          <li className="-mx-0.5">
            <p className="text-coolGray-500 -mx-1.5">{street}</p>
          </li>
        </ul>
      )}
    </div>
  );
};
