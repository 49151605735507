import { useEffect, useState } from 'react';
import {
  Immobilie,
  ImmobilieAusstattungFussbodenbelag,
  ImmobilieBauweiseKellerart,
  ImmobilieBauweiseKellerausbaustufe,
  ImmobilieObjektinformationenDacheindeckung,
  ImmobilieObjektinformationenHeizungen,
  useUpdateEstateData,
} from '@dtk/query';
import {
  Button,
  DropdownField,
  FeedbackVariant,
  InputField,
  MessageBanner,
  RadioButtons,
  validationMessages,
} from '@dtk/ui-components';
import { Form, Formik } from 'formik';
import {
  formValidationSchema,
  AusstattungInfo,
  badtypOptions,
  fensterOptions,
  abstellraumOptions,
  jaNeinBalkon,
  jaNeinGarten,
  jaNeinBeader,
  jaNeinOffenerKamin,
  jaNeinSauna,
  jaNeinUeberwiegendeAufputzleitung,
} from './constant';
import { mapImmobilieDtoToForm, mapFormToImmobilieDto } from './utils';
import { trackFormError, trackFormStart, trackFormSuccess } from '@dtk/user-tracking';
import { AENDERUNGEN_UEBERNOMMEN_30_SEK } from '../../labels';

export interface AusstattungFormProps {
  estateValues: Immobilie | undefined;
  editable: boolean;
}

const formName = 'immobiliendaten.austattung';
export function AusstattungForm({ estateValues, editable }: AusstattungFormProps) {
  const [feedbackState, setFeedbackState] = useState({ showFeedback: false, headline: '', variant: 'success' });
  const ausstattungValues = mapImmobilieDtoToForm(estateValues);
  const { mutate } = useUpdateEstateData();

  useEffect(() => trackFormStart(formName), []);

  let isInitalValid;
  formValidationSchema.isValid(ausstattungValues).then((isValid) => {
    isInitalValid = isValid;
  });

  const handleSubmit = (values: AusstattungInfo) => {
    mutate(
      {
        data: mapFormToImmobilieDto(values),
      },
      {
        onSuccess: async () => {
          trackFormSuccess(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: AENDERUNGEN_UEBERNOMMEN_30_SEK,
            variant: 'success',
          });
        },
        onError: () => {
          trackFormError(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: validationMessages.error.portalFormBanner.text,
            variant: 'error',
          });
        },
      }
    );
  };

  return (
    <Formik<AusstattungInfo>
      validateOnMount={true}
      isInitialValid={isInitalValid}
      initialValues={ausstattungValues}
      validationSchema={formValidationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <div data-testid="ausstattung">
          {feedbackState.showFeedback && (
            <div className="pb-2">
              <MessageBanner
                message={feedbackState.headline}
                isError={(feedbackState.variant as FeedbackVariant) === 'error'}
                isSuccess={(feedbackState.variant as FeedbackVariant) === 'success'}
              />
            </div>
          )}
          <div className="grid grid-cols-6 gap-x-6 gap-y-4">
            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                disabled={!editable}
                aria-label="badtyp"
                name="badtyp"
                label="Badtyp"
                options={Object.values({ '': 'Keine Angabe', ...badtypOptions })}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                disabled={!editable}
                aria-label="abstellraum"
                name="abstellraum"
                label="Abstellraum"
                options={Object.values({ '': 'Keine Angabe', ...abstellraumOptions })}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                disabled={!editable}
                aria-label="kellerausbaustufe"
                name="kellerausbaustufe"
                label="Ausbaustufe Keller"
                options={Object.values(ImmobilieBauweiseKellerausbaustufe)}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                disabled={!editable}
                aria-label="kellerart"
                name="kellerart"
                label="Kellerart"
                options={Object.values(ImmobilieBauweiseKellerart)}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                disabled={!editable}
                aria-label="fussbodenbelag"
                name="fussbodenbelag"
                label="Fußboden"
                options={Object.values({ '': 'Keine Angabe', ...ImmobilieAusstattungFussbodenbelag })}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                disabled={!editable}
                aria-label="heizung"
                name="heizung"
                label="Heizung"
                options={Object.values({ '': 'Keine Angabe', ...ImmobilieObjektinformationenHeizungen })}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                disabled={!editable}
                aria-label="fenster"
                name="fenster"
                label="Fenster"
                options={Object.values({ '': 'Keine Angabe', ...fensterOptions })}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                disabled={!editable}
                aria-label="dacheindeckung"
                name="dacheindeckung"
                label="Dacheindeckung"
                options={Object.values({ '': 'Keine Angabe', ...ImmobilieObjektinformationenDacheindeckung })}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <InputField
                data-testid="garagenstellplatz"
                name="garagenstellplatz"
                label="Garagenstellplätze"
                type="number"
                infoMessage={validationMessages.info.garageParkingSpaces}
                disabled={!editable}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <InputField
                data-testid="stellplatzImFreien"
                name="stellplatzImFreien"
                label="Stellplätze im Freien"
                type="number"
                infoMessage={validationMessages.info.outdoorParkingSpaces}
                disabled={!editable}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <RadioButtons {...jaNeinBalkon} disabled={!editable} />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <RadioButtons {...jaNeinGarten} disabled={!editable} />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <RadioButtons {...jaNeinBeader} disabled={!editable} />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <RadioButtons {...jaNeinOffenerKamin} disabled={!editable} />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <RadioButtons {...jaNeinSauna} disabled={!editable} />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <RadioButtons {...jaNeinUeberwiegendeAufputzleitung} disabled={!editable} />
            </div>
          </div>
        </div>
        {editable && (
          <Button className="mt-4 font-semibold" type="submit">
            Speichern
          </Button>
        )}
      </Form>
    </Formik>
  );
}
