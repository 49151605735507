import { ImmobilieBauweiseBauweise, ImmobilieBauweiseDachausbau, Immobilie } from '@dtk/query';

import { BauweiseInfo, initialValueBauweiseFrom, dachausbauOptions } from './constant';

const getKeyByValue = (object: Record<string, string>, value: string): string | undefined => {
  return Object.keys(object).find((key) => object[key] === value);
};

export function mapFormToImmobilieDto(bauweiseData: BauweiseInfo): Immobilie {
  const immobilienDto: Immobilie = {
    bauweise: {
      bauweise: bauweiseData.bauweise as ImmobilieBauweiseBauweise,
      dachausbau: getKeyByValue(dachausbauOptions, bauweiseData.dachausbau) as ImmobilieBauweiseDachausbau,
    },
    ausstattung: {
      aussenwaendeUeberwiegendGedaemmt: (bauweiseData.aussenwaendeUeberwiegendGedaemmt as string) === 'ja',
    },
  };

  return immobilienDto;
}

export function mapImmobilieDtoToForm(immobilie: Immobilie | undefined): BauweiseInfo {
  if (!immobilie) {
    return initialValueBauweiseFrom;
  }

  const { bauweise, ausstattung } = immobilie;

  return {
    bauweise: bauweise?.bauweise as string,
    dachausbau: dachausbauOptions[bauweise?.dachausbau || ''] as string,
    aussenwaendeUeberwiegendGedaemmt: (ausstattung?.aussenwaendeUeberwiegendGedaemmt as boolean) ? 'ja' : 'nein',
  };
}
