import { formatCurrency } from '@dtk/formatter';
import classNames from 'classnames';

export interface DisplayTrend {
  years: string;
  relative: number;
  absolute: number;
}
export interface PricingTrendTableProps {
  trends: DisplayTrend[];
}

export function PricingTrendTable({ trends }: PricingTrendTableProps) {
  const isPostive = (value: number): boolean => {
    return Math.sign(value) > 0;
  };
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 text-base">
              <thead className="bg-coolGray-100 w-full">
                <tr>
                  <th scope="col" className="text-coolGray-500 px-6 py-3 text-left font-medium tracking-wider">
                    Jahr
                  </th>
                  <th scope="col" className="text-coolGray-500 px-6 py-3 text-left font-medium tracking-wider">
                    Relativ
                  </th>
                  <th
                    scope="col"
                    className="text-coolGray-500 px-6 py-3 text-left text-base font-medium uppercase tracking-wider"
                  >
                    EUR
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {trends.length > 0 &&
                  trends.map((trend) => (
                    <tr key={trend.years}>
                      <td data-testid="year" className="text-navy whitespace-nowrap px-6 py-4">
                        {trend.years}
                      </td>
                      <td
                        data-testid="relative"
                        className={classNames(
                          isPostive(trend.relative as number) ? 'text-green-400' : 'text-red',
                          'whitespace-nowrap px-6 py-4'
                        )}
                      >
                        {isPostive(trend.relative as number) && '+'}
                        {Number(trend.relative).toFixed(2)}
                        {'%'}
                      </td>
                      <td data-testid="absolute" className="text-navy whitespace-nowrap px-6 py-4">
                        {isPostive(trend.absolute as number) && '+'}
                        {formatCurrency(trend.absolute, 0)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
