import { CardHeader } from '@dtk/ui-components';
import { EstateValueCard } from './EstateValue';
import { PricingTrendTableCard } from './PricingTrendTable';
import { trackPropertyInformation } from '@dtk/user-tracking';
import { useEffect } from 'react';

interface EstateTrendSectionProps {
  withHeader?: boolean;
}

export function EstateTrendSection({ withHeader = false }: EstateTrendSectionProps) {
  useEffect(() => {
    trackPropertyInformation('start', 'chart');
    const timer = setTimeout(() => trackPropertyInformation('actively engaged', 'chart'), 30_000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="space-y-0">
      {withHeader && (
        <CardHeader
          headline="Was ist meine Immobilie wert?"
          description="Hier finden Sie eine Übersicht über alle notwendigen Parameter, die den Wert Ihrer Immobilie ausmachen"
          badge="Meine Immobilie"
        />
      )}
      {/* TODO: BEIDE IMMER ANZEIGEN */}
      <div className="lg:flex lg:flex-wrap">
        <section className="mt-4 lg:w-1/3 lg:pr-4">
          <EstateValueCard />
        </section>
        <section className="mt-4 lg:w-2/3 ">
          <PricingTrendTableCard />
        </section>
      </div>
    </div>
  );
}
