import { Button } from '@dtk/ui-components';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useState } from 'react';
import { PencilIcon, XIcon } from '@heroicons/react/solid';
import { DocumentTextIcon } from '@heroicons/react/outline';

export interface ModalEstateDataProps {
  headline: string;
  subline: string;
  editable?: boolean;
  children?: ReactNode;
}

export function ModalEditEstate({ headline, subline, editable = true, children }: ModalEstateDataProps) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      {editable ? (
        <div className="hidden lg:block">
          <Button
            variant="white"
            iconPosition="left"
            icon={PencilIcon}
            onClick={openModal}
            className=" border border-gray-300"
          >
            Bearbeiten
          </Button>
        </div>
      ) : (
        <div>
          <Button
            variant="white"
            iconPosition="left"
            icon={DocumentTextIcon}
            onClick={openModal}
            className="border border-gray-300"
          >
            Anzeigen
          </Button>
        </div>
      )}
      {/* Blur Background effect */}
      {isOpen && (
        <div className="relative">
          <div className="fixed inset-0 z-40 bg-black bg-opacity-50"></div>
        </div>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="bg-coolGray-50 my-8 inline-block w-full max-w-4xl transform overflow-hidden p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title>
                  <div className="flex flex-row items-center justify-between">
                    <p className="text-gold text-2xl">{headline}</p>
                    <Button
                      className="border border-gray-300"
                      variant="white"
                      size="sm"
                      iconPosition="right"
                      icon={XIcon}
                      onClick={closeModal}
                    >
                      Schließen
                    </Button>
                  </div>
                </Dialog.Title>
                <Dialog.Description className="text-navy mt-2 text-base">{subline}</Dialog.Description>
                <div className="md:h-modal mt-2 h-auto overflow-y-scroll p-1">{children}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
