import { ImmobilieBasisdatenGeschosslage, ImmobilieBasisdatenImmobilientyp } from '@dtk/query';
import {
  MAXIMUM_CONSTRUCTION_YEAR,
  MAXIMUM_LAND_AREA,
  MAXIMUM_LIVING_SPACE,
  MINIMUM_CONSTRUCTION_YEAR,
  MINIMUM_LIVING_SPACE,
  validationMessages,
} from '@dtk/ui-components';
import { object, string, number, mixed } from 'yup';

export interface BasisDatenInfo {
  immobilienPlz: string;
  immobilienCity: string;
  immobilienStreet: string;
  immobilienHouseNumber: string;

  immobilienTyp: ImmobilieBasisdatenImmobilientyp | string;
  wohneinheiten: number;
  wohnflaeche: number;
  grundstueckflaeche: number;
  baujahr: number | string;
  anzahlGeschosse?: number;
  geschosslage?: ImmobilieBasisdatenGeschosslage | string;
}

export const anzahlGeschosse = [1, 2, 3, 4, 5];

export const initialValueBasisDatenFrom: BasisDatenInfo = {
  immobilienTyp: '',
  wohneinheiten: 0,
  geschosslage: '',
  wohnflaeche: 0,
  grundstueckflaeche: 0,
  baujahr: '',
  immobilienPlz: '',
  immobilienCity: '',
  immobilienStreet: '',
  immobilienHouseNumber: '',
  anzahlGeschosse: 1,
};

export const basisDatenFormSchema = object({
  immobilienTyp: mixed<string>()
    .oneOf(Object.values(ImmobilieBasisdatenImmobilientyp), validationMessages.error.defaultDropdownField.choiceText)
    .required(validationMessages.error.defaultDropdownField.text),
  wohneinheiten: number().required(validationMessages.error.defaultInputField.promptText),
  geschosslage: mixed<string>().when('immobilienTyp', {
    is: ImmobilieBasisdatenImmobilientyp.Eigentumswohnung,
    then: string()
      .oneOf(Object.values(ImmobilieBasisdatenGeschosslage), validationMessages.error.defaultDropdownField.choiceText)
      .required(validationMessages.error.defaultDropdownField.text),
  }),
  anzahlGeschosse: string().when('immobilienTyp', {
    is: !ImmobilieBasisdatenImmobilientyp.Eigentumswohnung,
    then: number()
      .oneOf(anzahlGeschosse, validationMessages.error.defaultDropdownField.choiceText)
      .required(validationMessages.error.defaultDropdownField.text),
  }),
  wohnflaeche: number()
    .min(MINIMUM_LIVING_SPACE, validationMessages.error.livingSpace.min.text)
    .max(MAXIMUM_LIVING_SPACE, validationMessages.error.livingSpace.max.text)
    .required(validationMessages.error.defaultInputField.promptText),
  grundstueckflaeche: number()
    .max(MAXIMUM_LAND_AREA, validationMessages.error.landArea.max.text)
    .required(validationMessages.error.defaultInputField.promptText),
  baujahr: number()
    .min(MINIMUM_CONSTRUCTION_YEAR, validationMessages.error.constructionYear.min.text)
    .max(MAXIMUM_CONSTRUCTION_YEAR, validationMessages.error.constructionYear.max.text)
    .required(validationMessages.error.defaultInputField.promptText),
  immobilienPlz: string()
    .length(5, validationMessages.error.zipCode.text)
    .matches(/^\d+$/, validationMessages.error.zipCode.text)
    .required(validationMessages.error.defaultInputField.promptText),
  immobilienCity: string().required(validationMessages.error.defaultInputField.promptText),
  immobilienStreet: string().required(validationMessages.error.defaultInputField.promptText),
  immobilienHouseNumber: string().required(validationMessages.error.defaultInputField.promptText),
});
