import { MouseEvent, TouchEvent } from 'react';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveCardinal } from '@visx/curve';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import { LinePath, Line, Circle } from '@visx/shape';
import { Text } from '@visx/text';
import { localPoint } from '@visx/event';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { bisector } from 'd3-array';

import { ChartTrend, formatTimeAxis } from './utils';
import { Tooltip, TooltipProps } from './Tooltip';
import { tailwindClassToThemeValue } from '@dtk/ui-components';

const colors = {
  coolGrey500: '#6B7280',
  goldSand: '#CDB991',
  white: '#FFFFFF',
  black: '#000000',
};
const offsetYInEuro = 650;
const offsetXInDays = 125;
const margin = {
  top: 50,
  right: 20,
  bottom: 30,
  left: 55,
};

export type PricingTrendChartProps = {
  trends: ChartTrend[];
  street: string | undefined;
  width: number;
  height: number;
  calculateNumTicksAxisBottom: (width: number) => number;
  axisValuesColor?: string;
  trendLineColor?: string;
};

export const PricingTrendChart = ({
  width,
  height,
  trends,
  street,
  calculateNumTicksAxisBottom,
  axisValuesColor,
  trendLineColor,
}: PricingTrendChartProps) => {
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<TooltipProps>();

  const getX = (d: ChartTrend) => d.year;
  const getY = (d: ChartTrend) => d.totalValuePerQM;
  const offsetXinMilliseconds = offsetXInDays * 24 * 60 * 60 * 1000;
  const maxDate = new Date(Math.max(...trends.map((d) => getX(d).getTime())) + offsetXinMilliseconds);
  const minDate = new Date(Math.min(...trends.map((d) => getX(d).getTime())) - offsetXinMilliseconds);
  const minValue = Math.min(...trends.map(getY)) - offsetYInEuro; // Größter Immobilienwert
  const maxValue = Math.max(...trends.map(getY)) + offsetYInEuro; // kleinster Immobilienwert
  const boundX = width - margin.left - margin.right; // Verfügbare Breite
  const boundY = height - margin.top - margin.bottom; // Verfügbare Höhe
  const bisectX = bisector<ChartTrend, Date>((d: ChartTrend) => new Date(getX(d))).left;
  const xAxisScale = scaleTime<number>({
    domain: [minDate, maxDate],
    range: [0, boundX],
  });
  const yAxisScale = scaleLinear<number>({
    domain: [minValue, maxValue],
    range: [boundY, 0],
  });
  const xScale = scaleTime<number>({
    domain: [minDate, maxDate],
    range: [0 + margin.left, boundX + margin.left],
  });
  const yScale = scaleLinear<number>({
    domain: [minValue, maxValue],
    range: [boundY + margin.top, 0 + margin.top],
  });
  const numTicksAxisBottom = calculateNumTicksAxisBottom(width);
  let tooltipTimeout: number;
  const handleTooltip = (event: TouchEvent | MouseEvent) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    const coords = localPoint(event);
    if (!coords) return;
    const x0 = xScale.invert(coords.x);
    const index = bisectX(trends, x0, 1);
    const d0 = trends[index - 1];
    const d1 = trends[index];
    let d = d0;
    if (d1) {
      d = x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf() ? d1 : d0;
    }
    showTooltip({
      tooltipData: { trend: d, street },
      tooltipLeft: xScale(getX(d)),
      tooltipTop: yScale(getY(d)),
    });
  };

  return (
    <div className="relative">
      <svg x={0} y={0} width={width} height={height}>
        <Text
          x={margin.left * 0.5}
          y={margin.top * 0.4}
          textAnchor={'middle'}
          fill={tailwindClassToThemeValue(axisValuesColor)}
        >
          € / m²
        </Text>
        <GridColumns scale={xAxisScale} left={margin.left} top={margin.top} height={boundY} strokeDasharray={'5'} />
        <GridRows scale={yAxisScale} left={margin.left} top={margin.top} width={boundX} />
        <AxisBottom
          scale={xAxisScale}
          left={margin.left}
          top={height - margin.bottom}
          hideAxisLine={true}
          numTicks={numTicksAxisBottom}
          hideTicks={true}
          tickFormat={formatTimeAxis}
          tickLabelProps={() => ({
            fontSize: 14,
            fill: tailwindClassToThemeValue(axisValuesColor) || colors.coolGrey500,
            textAnchor: 'middle',
          })}
        />
        <AxisLeft
          scale={yAxisScale}
          left={margin.left}
          top={margin.top}
          hideAxisLine={true}
          hideTicks={true}
          numTicks={4}
          tickFormat={(label) => label.toLocaleString()}
          tickLabelProps={() => ({
            fontSize: 14,
            fill: tailwindClassToThemeValue(axisValuesColor) || colors.coolGrey500,
            textAnchor: 'end',
            dx: '-10',
          })}
        />
        <LinePath<ChartTrend>
          curve={curveCardinal}
          data={trends}
          x={(d) => xScale(getX(d))}
          y={(d) => yScale(getY(d))}
          stroke={tailwindClassToThemeValue(trendLineColor) || colors.goldSand}
          strokeWidth={3}
        />

        {/* thicker invisible line to hover for Tooltip */}
        <LinePath<ChartTrend>
          curve={curveCardinal}
          data={trends}
          x={(d) => xScale(getX(d))}
          y={(d) => yScale(getY(d))}
          stroke={colors.goldSand}
          strokeOpacity={0}
          strokeWidth={1000}
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => {
            tooltipTimeout = window.setTimeout(() => {
              hideTooltip();
            }, 300);
          }}
        />
        {tooltipOpen && (
          <>
            <Line
              from={{ x: tooltipLeft, y: height - margin.bottom }}
              to={{ x: tooltipLeft, y: tooltipTop }}
              stroke={colors.black}
              strokeWidth={1}
              strokeDasharray={5}
            />
            <Circle r={8} fill={colors.white} cx={tooltipLeft} cy={tooltipTop} />
            <Circle
              r={6}
              fill={tailwindClassToThemeValue(trendLineColor) || colors.goldSand}
              cx={tooltipLeft}
              cy={tooltipTop}
            />
          </>
        )}
      </svg>
      {tooltipOpen && (
        <>
          {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
          <TooltipWithBounds key={Math.random()} top={tooltipTop} left={tooltipLeft}>
            {tooltipData && <Tooltip {...tooltipData} />}
          </TooltipWithBounds>
        </>
      )}
    </div>
  );
};
