import { ChartBarIcon, ArrowUpIcon, ArrowDownIcon, ArrowRightIcon } from '@heroicons/react/solid';
import { formatCurrency } from '@dtk/formatter';

export interface EstateValueProps {
  estateValue: number;
  squareMeterPrice: number;
  relative: number;
  previousYear: number;
  desiredAmount: number | undefined;
  maxAmount: number | undefined;
}

const withPositiveNegativeColor = (value: number) => {
  if (value > 0) return 'text-green-400';
  else if (value < 0) return 'text-red';
  return 'text-cyan';
};

export const EstateValue = (args: EstateValueProps) => (
  <>
    <ol className="bg-navy-800 mb-4 rounded-xl p-8 pb-10 text-white lg:pb-7">
      <li className="bg-neutral mb-4 flex h-12 w-12 items-center justify-center rounded-full">
        <ChartBarIcon className="text-gold-gold h-5 w-5 " />
      </li>
      <li className="text-sm">Immobilienwert</li>
      <li className="text-4xl font-bold" data-testid="estateValue">
        {formatCurrency(args.estateValue, 0)}
      </li>
      <li className="text-coolGray-500 mb-2 text-lg font-semibold" data-testid="squareMeterPrice">
        ⌀ {formatCurrency(args.squareMeterPrice, 0)}/m²
      </li>
      <li className={`${withPositiveNegativeColor(args.relative)} bg-navy-navy inline-flex rounded p-1 font-bold`}>
        <span className="flex items-center pr-1" data-testid="arrowIcon">
          {args.relative > 0 && <ArrowUpIcon className="h-4 w-4" />}
          {args.relative < 0 && <ArrowDownIcon className="h-4 w-4" />}
          {args.relative === 0 && <ArrowRightIcon className="h-4 w-4" />}
        </span>
        <span className="pr-1" data-testid="relative">
          {args.relative}%
        </span>
        <span className="text-coolGray-500" data-testid="previousYear">
          seit {args.previousYear}
        </span>
      </li>
    </ol>
    <div className="pl-4">
      {args.desiredAmount && (
        <>
          <p className="text-navy-300 text-xs">Wunschauszahlung</p>
          <p className="text-base" data-testid="desiredAmount">
            {formatCurrency(args.desiredAmount, 0)}
          </p>
        </>
      )}
      {args.maxAmount && (
        <>
          <p className="text-navy-300 text-xs">Maximaler Auszahlungsbetrag</p>
          <p className="text-base" data-testid="maxAmount">
            {formatCurrency(args.maxAmount, 0)}
          </p>
        </>
      )}
    </div>
  </>
);
