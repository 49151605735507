import { Immobilie, useGetEstateData } from '@dtk/query';
import { Header, HeaderProps, Spinner } from '@dtk/ui-components';
import { CalendarIcon, DocumentIcon, DocumentTextIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import { EstateOverviewModal } from './EstateOverviewModal';

const mapImmobilieToHeaderProps = (immobilie: Immobilie): HeaderProps => {
  if (!immobilie?.address?.street) {
    return {
      bannerUrl: '/immobilie/images/Banner.jpg',
      name: 'Ihre Immobilie',
      iconValues: [],
    };
  }
  const headerProps = {
    bannerUrl: '/immobilie/images/Banner.jpg',
    name: immobilie.address?.street as string,
    iconValues: [
      {
        key: 'address',
        value: `${immobilie.address?.postCode} ${immobilie.address?.city}`,
        icon: LocationMarkerIcon,
      },
      {
        key: 'createdAt',
        value: `${formatDate(immobilie.createdAt as string)}`,
        icon: CalendarIcon,
      },
      {
        key: 'wohlfaeche',
        value: `${immobilie.basisdaten?.wohnflaeche}m² Wohnfläche`,
        icon: DocumentIcon,
      },
      {
        key: 'grundstuecksflaeche',
        value: `${immobilie.basisdaten?.grundstuecksflaeche}m² Grundstücksfläche`,
        icon: DocumentTextIcon,
      },
    ],
  };

  const iconValuesWithoutNull = headerProps.iconValues.filter((e) => !e.value.includes('null'));

  return { ...headerProps, iconValues: iconValuesWithoutNull };
};

const formatDate = (timestamp: string) => {
  const date = new Date(timestamp);
  return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
};

export function EstateHeader() {
  const { data: estateData, isLoading } = useGetEstateData();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Header
      {...mapImmobilieToHeaderProps(estateData?.data as Immobilie)}
      button={<EstateOverviewModal editable={!estateData?.data.isRated} />}
    ></Header>
  );
}
