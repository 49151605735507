import {
  Immobilie,
  ImmobilieModernisierungModernisierungBadWc,
  ImmobilieModernisierungModernisierungBodenbelaege,
  ImmobilieModernisierungModernisierungDach,
  ImmobilieModernisierungModernisierungFenster,
  ImmobilieModernisierungModernisierungHeizung,
  ImmobilieModernisierungModernisierungRaumaufteilung,
  ImmobilieModernisierungModernisierungStromWasserHeizung,
  ImmobilieModernisierungModernisierungWaermedaemmung,
  useUpdateEstateData,
} from '@dtk/query';
import { Button, DropdownField, FeedbackVariant, MessageBanner, validationMessages } from '@dtk/ui-components';
import { trackFormError, trackFormStart, trackFormSuccess } from '@dtk/user-tracking';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { ModernisierungDatenFormSchema, ModernisierungDateninfo } from './constant';
import { mapImmobilieDtoToModernisierungsForm, mapModernisierungsDatenToImmobilieDto } from './utils';
import { AENDERUNGEN_UEBERNOMMEN_30_SEK } from '../../labels';

export interface ModernisierungsFormProps {
  estateValues: Immobilie | undefined;
  editable: boolean;
}

const formName = 'immobiliendaten.modernisierung';

export function ModernisierungsForm({ estateValues, editable }: ModernisierungsFormProps) {
  const [feedbackState, setFeedbackState] = useState({
    showFeedback: false,
    headline: '',
    variant: 'success',
  });

  const modernisierungsDaten = mapImmobilieDtoToModernisierungsForm(estateValues);

  const { mutate } = useUpdateEstateData();

  let isInitalValid;
  ModernisierungDatenFormSchema.isValid(modernisierungsDaten).then((isValid) => {
    isInitalValid = isValid;
  });
  useEffect(() => trackFormStart(formName), []);
  const handleSubmit = (values: ModernisierungDateninfo) => {
    mutate(
      {
        data: mapModernisierungsDatenToImmobilieDto(values),
      },
      {
        onSuccess: async () => {
          trackFormSuccess(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: AENDERUNGEN_UEBERNOMMEN_30_SEK,
            variant: 'success',
          });
        },
        onError: () => {
          trackFormError(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: validationMessages.error.portalFormBanner.text,
            variant: 'error',
          });
        },
      }
    );
  };

  return (
    <Formik<ModernisierungDateninfo>
      validateOnMount={true}
      isInitialValid={isInitalValid}
      initialValues={modernisierungsDaten}
      validationSchema={ModernisierungDatenFormSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <div>
          {feedbackState.showFeedback && (
            <div className="pb-2">
              <MessageBanner
                message={feedbackState.headline}
                isError={(feedbackState.variant as FeedbackVariant) === 'error'}
                isSuccess={(feedbackState.variant as FeedbackVariant) === 'success'}
              />
            </div>
          )}
          <div className="grid grid-cols-6 gap-x-6 gap-y-4">
            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="baederWc"
                name="baederWc"
                label="Bäder & WC"
                options={Object.values(ImmobilieModernisierungModernisierungBadWc)}
                disabled={!editable}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="stromWasserHeizung"
                name="stromWasserHeizung"
                label="Strom - Wasser - Heizung"
                options={Object.values(ImmobilieModernisierungModernisierungStromWasserHeizung)}
                disabled={!editable}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="dach"
                name="dach"
                label="Dach"
                options={Object.values(ImmobilieModernisierungModernisierungDach)}
                disabled={!editable}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="fenster"
                name="fenster"
                label="Fenster"
                options={Object.values(ImmobilieModernisierungModernisierungFenster)}
                disabled={!editable}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="heizung"
                name="heizung"
                label="Heizung"
                options={Object.values(ImmobilieModernisierungModernisierungHeizung)}
                disabled={!editable}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="raumaufteilung"
                name="raumaufteilung"
                label="Raumaufteilung"
                options={Object.values(ImmobilieModernisierungModernisierungRaumaufteilung)}
                disabled={!editable}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="waermedaemmung"
                name="waermedaemmung"
                label="Wärmedämmmung"
                options={Object.values(ImmobilieModernisierungModernisierungWaermedaemmung)}
                disabled={!editable}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="boden"
                name="boden"
                label="Boden"
                options={Object.values(ImmobilieModernisierungModernisierungBodenbelaege)}
                disabled={!editable}
              />
            </div>
          </div>
        </div>
        {editable && (
          <Button className="mt-4 font-semibold" type="submit">
            Speichern
          </Button>
        )}
      </Form>
    </Formik>
  );
}
