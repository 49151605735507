import { Immobilie, useUpdateEstateData } from '@dtk/query';
import {
  Button,
  FeedbackVariant,
  InputFieldWithAddOn,
  MessageBanner,
  RadioButtons,
  validationMessages,
} from '@dtk/ui-components';
import { trackFormError, trackFormStart, trackFormSuccess } from '@dtk/user-tracking';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { FinanzielleDatenInfo, FinazielleDatenFormSchema, jaNeinRadioButtons } from './constant';
import { mapFinanzielleDatenToImmobilieDto, mapImmobilieDtoToFinanzielleDatenForm } from './utils';
import { AENDERUNGEN_UEBERNOMMEN_30_SEK } from '../../labels';

export interface FinanzielleDatenFormProps {
  estateValues: Immobilie | undefined;
  editable: boolean;
}

const formName = 'immobiliendaten.finanzielleDaten';
export function FinanzielleDatenForm({ estateValues, editable }: FinanzielleDatenFormProps) {
  const [feedbackState, setFeedbackState] = useState({
    showFeedback: false,
    headline: '',
    variant: 'success',
  });
  const finanzielleDatenValues = mapImmobilieDtoToFinanzielleDatenForm(estateValues);

  useEffect(() => trackFormStart(formName), []);
  const { mutate } = useUpdateEstateData();

  let isInitalValid;
  FinazielleDatenFormSchema.isValid(finanzielleDatenValues).then((isValid) => {
    isInitalValid = isValid;
  });

  const handleSubmit = (values: FinanzielleDatenInfo) => {
    mutate(
      {
        data: mapFinanzielleDatenToImmobilieDto(values),
      },
      {
        onSuccess: async () => {
          trackFormSuccess(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: AENDERUNGEN_UEBERNOMMEN_30_SEK,
            variant: 'success',
          });
        },
        onError: () => {
          trackFormError(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: validationMessages.error.portalFormBanner.text,
            variant: 'error',
          });
        },
      }
    );
  };

  return (
    <Formik<FinanzielleDatenInfo>
      validateOnMount={true}
      isInitialValid={isInitalValid}
      initialValues={finanzielleDatenValues}
      validationSchema={FinazielleDatenFormSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <div>
            {feedbackState.showFeedback && (
              <div className="pb-2">
                <MessageBanner
                  message={feedbackState.headline}
                  isError={(feedbackState.variant as FeedbackVariant) === 'error'}
                  isSuccess={(feedbackState.variant as FeedbackVariant) === 'success'}
                />
              </div>
            )}
            <div className="grid grid-cols-6 gap-x-6 gap-y-4">
              <div className="col-span-6 sm:col-span-3">
                <InputFieldWithAddOn
                  suffix={'€'}
                  data-testid="geschaetzterWert"
                  name="geschaetzterWert"
                  label="Geschätzter Immobilienwert"
                  infoMessage={validationMessages.info.realEstateValue}
                  disabled={!editable}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputFieldWithAddOn
                  suffix={'€'}
                  data-testid="wunschWert"
                  name="wunschWert"
                  label="Ihre Wunschauszahlung"
                  infoMessage={validationMessages.info.requestedPayout}
                  disabled={!editable}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <RadioButtons {...jaNeinRadioButtons} disabled={!editable} />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputFieldWithAddOn
                  suffix={'€'}
                  data-testid="bestehendeLastenBetrag"
                  name="bestehendeLastenBetrag"
                  label="Betrag bestehende Lasten"
                  infoMessage={validationMessages.info.existingLoadsAmount}
                  disabled={!editable || values.bestehendeLasten === 'nein'}
                />
              </div>
            </div>
          </div>
          {editable && (
            <Button className="mt-4 font-semibold" type="submit">
              Speichern
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
}
