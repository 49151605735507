import { ModalEditEstate } from '../02_composite';
import { EstateForms } from '../02_composite/forms';

interface EstateOverviewModalProps {
  editable: boolean;
}

export function EstateOverviewModal({ editable = false }: EstateOverviewModalProps) {
  const sublineText = editable
    ? 'Hier können Sie Ihre Angaben zu Ihrer Immobilie bearbeiten.'
    : 'Hier können Sie Ihre Angaben zu Ihrer Immobilie einsehen.';

  return (
    <ModalEditEstate headline="Ihre Angaben zur Ihrer Immobilie" subline={sublineText} editable={editable}>
      <EstateForms editable={editable} />
    </ModalEditEstate>
  );
}
