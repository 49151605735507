import { Immobilie, useGetEstateData } from '@dtk/query';
import { Spinner, Tab, Tabs } from '@dtk/ui-components';
import { useState } from 'react';
import { BasisDatenForm } from './BasisDaten';
import { FinanzielleDatenForm } from './FinanzielleDaten';
import { EnergieeffizienzForm } from './Energieeffizienz';
import { BauweiseForm } from './Bauweise';
import { ModernisierungsForm } from './ModernisierungsDaten';
import { AusstattungForm } from './Ausstattung';

type EstateTab =
  | 'Basisdaten'
  | 'Finanzielle Daten'
  | 'Modernisierungen'
  | 'Ausstattung'
  | 'Bauweise'
  | 'Energieeffizienz';

const estateOverviewTabs: Tab[] = [
  { name: 'Basisdaten', current: true },
  { name: 'Finanzielle Daten', current: false },
  { name: 'Modernisierungen', current: false },
  { name: 'Ausstattung', current: false },
  { name: 'Bauweise', current: false },
  { name: 'Energieeffizienz', current: false },
];
interface EstateFormsProps {
  editable: boolean;
}

export function EstateForms({ editable }: EstateFormsProps) {
  const { data: estateData, isLoading } = useGetEstateData();
  const [currentTab, setCurrentTab] = useState<EstateTab>('Basisdaten');

  return (
    <>
      <div className="my-8">
        <Tabs
          tabs={mapCurrentTabToEstateOverviewTabs(currentTab)}
          handleCurrent={(selectedTab) => setCurrentTab(selectedTab as EstateTab)}
        ></Tabs>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {currentTab === 'Basisdaten' && (
            <BasisDatenForm editable={editable} estateValues={estateData?.data as Immobilie} />
          )}
          {currentTab === 'Finanzielle Daten' && (
            <FinanzielleDatenForm editable={editable} estateValues={estateData?.data as Immobilie} />
          )}
          {currentTab === 'Ausstattung' && (
            <AusstattungForm editable={editable} estateValues={estateData?.data as Immobilie} />
          )}
          {currentTab === 'Modernisierungen' && (
            <ModernisierungsForm editable={editable} estateValues={estateData?.data as Immobilie} />
          )}
          {currentTab === 'Bauweise' && (
            <BauweiseForm editable={editable} estateValues={estateData?.data as Immobilie} />
          )}
          {currentTab === 'Energieeffizienz' && (
            <EnergieeffizienzForm editable={editable} estateValues={estateData?.data as Immobilie} />
          )}
        </>
      )}
    </>
  );
}

export const mapCurrentTabToEstateOverviewTabs = (currentTab: EstateTab): Tab[] => {
  return estateOverviewTabs.map((tab) => {
    if (tab.name === currentTab) {
      return { ...tab, current: true };
    } else {
      return { ...tab, current: false };
    }
  });
};
