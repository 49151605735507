import { Immobilie, ImmobilieFinanzielleDaten } from '@dtk/query';
import { FinanzielleDatenInfo, initalValuesFinazielleDatenForm } from './constant';

export function mapFinanzielleDatenToImmobilieDto(finanzielleDaten: FinanzielleDatenInfo): Immobilie {
  const finanzDto: ImmobilieFinanzielleDaten = {
    geschaetzterImmobilienwert: finanzielleDaten.geschaetzterWert as number,
    wunschauszahlung: finanzielleDaten.wunschWert as number,
    hatBestehendeLast: (finanzielleDaten.bestehendeLasten as string) === 'ja' ? true : false,
    bestehendeLast: finanzielleDaten.bestehendeLastenBetrag as number,
  };

  if (!finanzDto.hatBestehendeLast) {
    return { finanzielleDaten: { ...finanzDto, bestehendeLast: 0 } };
  }

  return { finanzielleDaten: { ...finanzDto } };
}

export function mapImmobilieDtoToFinanzielleDatenForm(immobilie: Immobilie | undefined): FinanzielleDatenInfo {
  if (!immobilie || !immobilie?.finanzielleDaten) {
    return initalValuesFinazielleDatenForm;
  }

  const finanzielleDaten: ImmobilieFinanzielleDaten = immobilie?.finanzielleDaten;

  return {
    geschaetzterWert: finanzielleDaten?.geschaetzterImmobilienwert as number,
    wunschWert: finanzielleDaten?.wunschauszahlung as number,
    bestehendeLasten: (finanzielleDaten?.hatBestehendeLast as boolean) ? 'ja' : 'nein',
    bestehendeLastenBetrag: finanzielleDaten?.bestehendeLast as number,
  };
}
