import { PricingTrend, Trend } from '@dtk/query';
import { NumberValue } from 'd3-scale';

export const formatTimeAxis = (value: Date | NumberValue): string => {
  const date = new Date(value.toString());
  switch (date.getMonth()) {
    case 0:
      return date.getFullYear().toString();
    case 1:
    case 2:
      return ''; // should not happen
    case 3:
    case 4:
    case 5:
      return 'Q2';
    case 6:
    case 7:
    case 8:
      return 'Q3';
    case 9:
    case 10:
    case 11:
      return 'Q4';
    default:
      return ''; // should not happen
  }
};
export const calculateNumTicksAxisBottom = (width: number): number => {
  if (width > 1000) return 20;
  if (width > 600) return 10;
  return 5;
};

export const mapToChartData = (pricingTrend: PricingTrend, wohnflaeche: number): ChartTrend[] => {
  const pastValues = pricingTrend.past?.map((trend) => mapTrend(trend, wohnflaeche)) || [];
  const futureValues = pricingTrend.future?.map((trend) => mapTrend(trend, wohnflaeche)) || [];
  return [...pastValues, ...futureValues];
};

export const mapTrend = (trend: Trend, wohnflaeche: number): ChartTrend => {
  return {
    absolute: trend.absolute ? parseFloat(trend.absolute?.toFixed(0)) : undefined,
    relative: trend.relative ? parseFloat(trend.relative?.toFixed(2)) : undefined,
    year: new Date(trend.year as string),
    totalValuePerQM: trend.totalValue ? parseFloat((trend.totalValue / wohnflaeche).toFixed(0)) : 0,
  };
};

export interface DisplayTrend {
  years: string;
  relative: number;
  absolute: number;
}

export type ChartTrend = {
  year: Date;
  relative: number | undefined;
  absolute: number | undefined;
  totalValuePerQM: number;
};
