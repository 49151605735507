import {
  ImmobilieAusstattungBadtyp,
  ImmobilieAusstattungAbstellraum,
  ImmobilieAusstattungFussbodenbelag,
  ImmobilieBauweiseKellerart,
  ImmobilieBauweiseKellerausbaustufe,
  ImmobilieObjektinformationenHeizungen,
  ImmobilieObjektinformationenFenster,
  ImmobilieObjektinformationenDacheindeckung,
} from '@dtk/query';
import { RadioButtonProps, validationMessages } from '@dtk/ui-components';
import { object, mixed, string, number } from 'yup';

export const badtypOptions = {
  [ImmobilieAusstattungBadtyp.innenliegend]: 'Innenliegend',
  [ImmobilieAusstattungBadtyp.mehr_als_ein_Bad]: 'Mehr als ein Bad',
  [ImmobilieAusstattungBadtyp.mit_Fenster]: 'Mit Fenster',
};

export const abstellraumOptions = {
  [ImmobilieAusstattungAbstellraum.auerhalb_der_Wohnung]: 'Außerhalb der Wohnung',
  [ImmobilieAusstattungAbstellraum.innerhalb_der_Wohnung]: 'Innerhalb der Wohnung',
  [ImmobilieAusstattungAbstellraum.innerhalb_und_auerhalb_der_Wohnung]: 'Innerhalb und außerhalb der Wohnung',
  [ImmobilieAusstattungAbstellraum.Keiner]: 'Keiner',
};

export const fensterOptions = {
  [ImmobilieObjektinformationenFenster.einfach_verglast]: 'Einfach verglast',
  [ImmobilieObjektinformationenFenster.zweifach_verglast]: 'Zweifach verglast',
  [ImmobilieObjektinformationenFenster.dreifach_verglast]: 'Dreifach verglast',
  [ImmobilieObjektinformationenFenster.groe_feststehende_Fensterflchen]: 'Große feststehende Fensterflächen',
  [ImmobilieObjektinformationenFenster.Kastenfenster]: 'Kastenfenster',
  [ImmobilieObjektinformationenFenster.Spezialverglasung]: 'Spezialverglasung',
  [ImmobilieObjektinformationenFenster.Sonstige]: 'Sonstige',
};

export const formValidationSchema = object({
  badtyp: mixed<string>()
    .oneOf(Object.values(badtypOptions), validationMessages.error.defaultDropdownField.choiceText)
    .required(validationMessages.error.defaultDropdownField.text),
  abstellraum: mixed<string>()
    .oneOf(Object.values(abstellraumOptions), validationMessages.error.defaultDropdownField.choiceText)
    .required(validationMessages.error.defaultDropdownField.text),
  fussbodenbelag: mixed<string>()
    .oneOf(Object.values(ImmobilieAusstattungFussbodenbelag), validationMessages.error.defaultDropdownField.choiceText)
    .required(validationMessages.error.defaultDropdownField.text),
  kellerart: mixed<string>()
    .oneOf(Object.values(ImmobilieBauweiseKellerart), validationMessages.error.defaultDropdownField.choiceText)
    .required(validationMessages.error.defaultDropdownField.text),
  kellerausbaustufe: mixed<string>()
    .oneOf(Object.values(ImmobilieBauweiseKellerausbaustufe), validationMessages.error.defaultDropdownField.choiceText)
    .required(validationMessages.error.defaultDropdownField.text),
  heizung: mixed<string>()
    .oneOf(
      Object.values(ImmobilieObjektinformationenHeizungen),
      validationMessages.error.defaultDropdownField.choiceText
    )
    .required(validationMessages.error.defaultDropdownField.text),
  fenster: mixed<string>()
    .oneOf(Object.values(fensterOptions), validationMessages.error.defaultDropdownField.choiceText)
    .required(validationMessages.error.defaultDropdownField.text),
  dacheindeckung: mixed<string>()
    .oneOf(
      Object.values(ImmobilieObjektinformationenDacheindeckung),
      validationMessages.error.defaultDropdownField.choiceText
    )
    .required(validationMessages.error.defaultDropdownField.text),
  garagenstellplatz: number()
    .max(4, validationMessages.error.garageParkingSpaces.max(4).text)
    .required(validationMessages.error.defaultDropdownField.text),
  stellplatzImFreien: number()
    .max(4, validationMessages.error.outdoorParkingSpaces.max(4).text)
    .required(validationMessages.error.defaultDropdownField.text),
  balkon: string().required(validationMessages.error.defaultDropdownField.text),
  garten: string().required(validationMessages.error.defaultDropdownField.text),
  zusatzBaeder: string().required(validationMessages.error.defaultDropdownField.text),
  offenerKamin: string().required(validationMessages.error.defaultDropdownField.text),
  sauna: string().required(validationMessages.error.defaultDropdownField.text),
  ueberwiegendeAufputzleitung: string().required(validationMessages.error.defaultDropdownField.text),
});

export const initialValuesFrom: AusstattungInfo = {
  badtyp: '',
  abstellraum: '',
  fussbodenbelag: '',
  kellerart: '',
  kellerausbaustufe: '',
  heizung: '',
  fenster: '',
  dacheindeckung: '',
  garagenstellplatz: 0,
  stellplatzImFreien: 0,
  balkon: '',
  garten: '',
  zusatzBaeder: '',
  offenerKamin: '',
  sauna: '',
  ueberwiegendeAufputzleitung: '',
};

export interface AusstattungInfo {
  badtyp: string;
  abstellraum: string;
  fussbodenbelag: ImmobilieAusstattungFussbodenbelag | string;
  kellerart: ImmobilieBauweiseKellerart | string;
  kellerausbaustufe: ImmobilieBauweiseKellerausbaustufe | string;
  heizung: ImmobilieObjektinformationenHeizungen | string;
  fenster: string;
  dacheindeckung: ImmobilieObjektinformationenDacheindeckung | string;
  garagenstellplatz: number;
  stellplatzImFreien: number;
  balkon: string;
  garten: string;
  zusatzBaeder: string;
  offenerKamin: string;
  sauna: string;
  ueberwiegendeAufputzleitung: string;
}

export const jaNeinBalkon: RadioButtonProps = {
  name: 'balkon',
  headline: 'Balkon',
  buttons: [
    { id: 'nein', title: 'Nein' },
    { id: 'ja', title: 'Ja' },
  ],
};
export const jaNeinGarten: RadioButtonProps = {
  name: 'garten',
  headline: 'Garten',
  buttons: [
    { id: 'nein', title: 'Nein' },
    { id: 'ja', title: 'Ja' },
  ],
};
export const jaNeinBeader: RadioButtonProps = {
  name: 'zusatzBaeder',
  headline: 'Mehrere Bäder',
  buttons: [
    { id: 'nein', title: 'Nein' },
    { id: 'ja', title: 'Ja' },
  ],
};
export const jaNeinOffenerKamin: RadioButtonProps = {
  name: 'offenerKamin',
  headline: 'Kamin oder Kachelofen',
  buttons: [
    { id: 'nein', title: 'Nein' },
    { id: 'ja', title: 'Ja' },
  ],
};
export const jaNeinSauna: RadioButtonProps = {
  name: 'sauna',
  headline: 'Sauna',
  buttons: [
    { id: 'nein', title: 'Nein' },
    { id: 'ja', title: 'Ja' },
  ],
};
export const jaNeinUeberwiegendeAufputzleitung: RadioButtonProps = {
  name: 'ueberwiegendeAufputzleitung',
  headline: 'Leitungen überwiegend auf Putz',
  buttons: [
    { id: 'nein', title: 'Nein' },
    { id: 'ja', title: 'Ja' },
  ],
};
