import { object, mixed, string } from 'yup';
import { RadioButtonProps, validationMessages } from '@dtk/ui-components';
import { ImmobilieBauweiseBauweise, ImmobilieBauweiseDachausbau } from '@dtk/query';

export const dachausbauOptions = {
  [ImmobilieBauweiseDachausbau.Voll_ausgebaut]: 'Voll ausgebaut',
  [ImmobilieBauweiseDachausbau.teilweise_ausgebaut]: 'Teilweise ausgebaut',
  [ImmobilieBauweiseDachausbau.nicht_ausgebaut]: 'Nicht ausgebaut',
  [ImmobilieBauweiseDachausbau.Flachdach]: 'Flachdach',
};

export const bauweiseFormSchema = object({
  bauweise: mixed<ImmobilieBauweiseBauweise>()
    .oneOf(Object.values(ImmobilieBauweiseBauweise), validationMessages.error.defaultDropdownField.choiceText)
    .required(validationMessages.error.defaultInputField.promptText),
  dachausbau: mixed<string>()
    .oneOf(Object.values(dachausbauOptions), validationMessages.error.defaultDropdownField.choiceText)
    .required(validationMessages.error.defaultInputField.promptText),
  aussenwaendeUeberwiegendGedaemmt: string().required(validationMessages.error.defaultInputField.promptText),
});

export const initialValueBauweiseFrom: BauweiseInfo = {
  bauweise: '',
  dachausbau: '',
  aussenwaendeUeberwiegendGedaemmt: '',
};

export interface BauweiseInfo {
  bauweise: ImmobilieBauweiseBauweise | string;
  dachausbau: string;
  aussenwaendeUeberwiegendGedaemmt: string;
}

export const jaNeinRadioButtons: RadioButtonProps = {
  name: 'aussenwaendeUeberwiegendGedaemmt',
  headline: 'Außenwände überwiegend gedämmt',
  buttons: [
    { id: 'nein', title: 'Nein' },
    { id: 'ja', title: 'Ja' },
  ],
};
