import { Trend, useGetEstatePricingTrend } from '@dtk/query';
import { Card, ErrorState, Spinner } from '@dtk/ui-components';
import { DisplayTrend, PricingTrendTable } from './PricingTrendTable';

export const PricingTrendTableCard = () => {
  const HEADLINE = 'Prognose';
  const { data: response, isLoading, error } = useGetEstatePricingTrend();

  if (isLoading) {
    <Card headline={HEADLINE}>
      <Spinner />
    </Card>;
  }
  //TODO: Error Message
  if (error) {
    <Card headline={HEADLINE}>
      <ErrorState state={'noDataAvailable'}/>
    </Card>;
  }

  if (!response?.data || !response.data.future) return null;

  return (
    <Card headline={HEADLINE}>
      <PricingTrendTable trends={mapResponse(response?.data?.future)} />
    </Card>
  );
};

const mapResponse = (future: Trend[]): DisplayTrend[] => {
  if (future.length === 0) {
    return [];
  }
  const sorted = future.sort((a, b) => Number(a.year) - Number(b.year));

  const displayTrends: DisplayTrend[] = [];

  sorted.forEach((trend) => {
    const years = `${trend.year}-${Number(trend.year) + 1}`;
    displayTrends.push({
      years,
      absolute: trend.absolute as number,
      relative: trend.relative as number,
    });
  });

  return displayTrends;
};
