import {
  Immobilie,
  ImmobilieBasisdatenGeschosslage,
  ImmobilieBasisdatenImmobilientyp,
  useUpdateEstateData,
} from '@dtk/query';
import {
  Button,
  DropdownField,
  FeedbackVariant,
  InputField,
  InputFieldCounter,
  InputFieldWithAddOn,
  MessageBanner,
  validationMessages,
} from '@dtk/ui-components';
import { trackFormError, trackFormStart, trackFormSuccess } from '@dtk/user-tracking';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { anzahlGeschosse, basisDatenFormSchema, BasisDatenInfo } from './constant';
import { mapBasisDatenFormToImmobilieDto, mapImmobilieDtoToBasisDatenForm } from './utils';
import { AENDERUNGEN_UEBERNOMMEN_30_SEK } from '../../labels';

export interface BasisDatenFormProps {
  estateValues: Immobilie | undefined;
  editable: boolean;
}

const formName = 'immobiliendaten.basisdaten';
export function BasisDatenForm({ estateValues, editable }: BasisDatenFormProps) {
  const [feedbackState, setFeedbackState] = useState({
    showFeedback: false,
    headline: '',
    variant: 'success',
  });

  useEffect(() => trackFormStart(formName), []);
  const basisDatenValues = mapImmobilieDtoToBasisDatenForm(estateValues);

  const { mutate } = useUpdateEstateData();

  let isInitalValid;
  basisDatenFormSchema.isValid(basisDatenValues).then((isValid) => {
    isInitalValid = isValid;
  });

  const handleSubmit = (values: BasisDatenInfo) => {
    mutate(
      {
        data: mapBasisDatenFormToImmobilieDto(values),
      },
      {
        onSuccess: async () => {
          trackFormSuccess(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: AENDERUNGEN_UEBERNOMMEN_30_SEK,
            variant: 'success',
          });
        },
        onError: () => {
          trackFormError(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: validationMessages.error.portalFormBanner.text,
            variant: 'error',
          });
        },
      }
    );
  };

  return (
    <Formik<BasisDatenInfo>
      validateOnMount={true}
      isInitialValid={isInitalValid}
      initialValues={basisDatenValues}
      validationSchema={basisDatenFormSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <div data-testid="basisdaten-address">
            {feedbackState.showFeedback && (
              <div className="pb-2">
                <MessageBanner
                  message={feedbackState.headline}
                  isError={(feedbackState.variant as FeedbackVariant) === 'error'}
                  isSuccess={(feedbackState.variant as FeedbackVariant) === 'success'}
                />
              </div>
            )}
            <div className="grid grid-cols-6 gap-x-6 gap-y-4">
              <div className="col-span-6 sm:col-span-2">
                <InputField
                  data-testid="immobilienPlz"
                  autoComplete="postal-code"
                  name="immobilienPlz"
                  label="PLZ"
                  type="number"
                  infoMessage={validationMessages.info.zipCode}
                  disabled={!editable}
                />
              </div>

              <div className="col-span-6 sm:col-span-4">
                <InputField
                  data-testid="immobilienCity"
                  name="immobilienCity"
                  label="Ort"
                  type="text"
                  infoMessage={validationMessages.info.city}
                  disabled={!editable}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  data-testid="immobilienStreet"
                  name="immobilienStreet"
                  label="Straße"
                  type="text"
                  infoMessage={validationMessages.info.street}
                  disabled={!editable}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  data-testid="immobilienHouseNumber"
                  name="immobilienHouseNumber"
                  label="Hausnummer"
                  type="text"
                  infoMessage={validationMessages.info.houseNr}
                  disabled={!editable}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <DropdownField
                  aria-label="immobilienTyp"
                  name="immobilienTyp"
                  label="Immobilientyp"
                  options={Object.values({ '': 'Keine Angabe', ...ImmobilieBasisdatenImmobilientyp })}
                  disabled={!editable}
                />
              </div>

              {values.immobilienTyp === ImmobilieBasisdatenImmobilientyp.Eigentumswohnung ? (
                <div className="col-span-6 sm:col-span-3">
                  <DropdownField
                    aria-label="geschosslage"
                    name="geschosslage"
                    label="Geschosslage der Wohnung"
                    options={Object.values({ '': 'Keine Angabe', ...ImmobilieBasisdatenGeschosslage })}
                    disabled={!editable}
                  />
                </div>
              ) : (
                <div className="col-span-6 sm:col-span-3">
                  <DropdownField
                    aria-label="anzahlGeschosse"
                    name="anzahlGeschosse"
                    label="Anzahl der Geschosse"
                    options={[...anzahlGeschosse].map(String)}
                    disabled={!editable}
                  />
                </div>
              )}

              <div className="col-span-6 sm:col-span-3">
                <InputFieldCounter
                  data-testid="wohneinheiten"
                  name="wohneinheiten"
                  label="Wohneinheiten"
                  type="number"
                  disabled={!editable}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputFieldWithAddOn
                  suffix={'m²'}
                  data-testid="wohnflaeche"
                  name="wohnflaeche"
                  label="Wohnfläche"
                  type="number"
                  infoMessage={validationMessages.info.livingSpace}
                  disabled={!editable}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputFieldWithAddOn
                  suffix={'m²'}
                  data-testid="grundstueckflaeche"
                  name="grundstueckflaeche"
                  label="Grundstücksfläche"
                  type="number"
                  infoMessage={validationMessages.info.landArea}
                  disabled={!editable}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  data-testid="baujahr"
                  name="baujahr"
                  label="Baujahr"
                  type="text"
                  infoMessage={validationMessages.info.constructionYear}
                  disabled={!editable}
                />
              </div>
            </div>
          </div>
          {editable && (
            <Button data-testid="" className="mt-4 font-semibold" type="submit">
              Speichern
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
}
