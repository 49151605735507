import { Immobilie, ImmobilieBasisdatenGeschosslage, ImmobilieBasisdatenImmobilientyp } from '@dtk/query';
import { BasisDatenInfo, initialValueBasisDatenFrom } from './constant';

export function mapBasisDatenFormToImmobilieDto(basisDaten: BasisDatenInfo): Immobilie {
  const immobilienDto: Immobilie = {
    basisdaten: {
      immobilientyp: basisDaten.immobilienTyp as ImmobilieBasisdatenImmobilientyp,
      baujahr: basisDaten.baujahr as string,
      wohneinheiten: basisDaten.wohneinheiten as number,
      geschosslage: basisDaten.geschosslage as ImmobilieBasisdatenGeschosslage,
      anzahlGeschosse: Number(basisDaten.anzahlGeschosse),
      grundstuecksflaeche: basisDaten.grundstueckflaeche as number,
      wohnflaeche: basisDaten.wohnflaeche as number,
    },
    address: {
      street: basisDaten.immobilienStreet as string,
      postCode: String(basisDaten.immobilienPlz) as string,
      city: basisDaten.immobilienCity as string,
      houseNumber: basisDaten.immobilienHouseNumber as string,
    },
  };

  if (immobilienDto?.basisdaten?.immobilientyp === ImmobilieBasisdatenImmobilientyp.Eigentumswohnung) {
    delete immobilienDto?.basisdaten?.anzahlGeschosse;
  } else {
    delete immobilienDto?.basisdaten?.geschosslage;
  }
  return immobilienDto;
}

export function mapImmobilieDtoToBasisDatenForm(immobilie: Immobilie | undefined): BasisDatenInfo {
  if (!immobilie) {
    return initialValueBasisDatenFrom;
  }

  return {
    immobilienTyp: (immobilie.basisdaten?.immobilientyp as string) || '',
    wohneinheiten: (immobilie.basisdaten?.wohneinheiten as number) || 0,
    geschosslage: (immobilie.basisdaten?.geschosslage as string) || '',
    wohnflaeche: (immobilie.basisdaten?.wohnflaeche as number) || 0,
    grundstueckflaeche: (immobilie.basisdaten?.grundstuecksflaeche as number) || 0,
    baujahr: (immobilie.basisdaten?.baujahr as string) || '',
    anzahlGeschosse: immobilie.basisdaten?.anzahlGeschosse || 1,
    immobilienPlz: (immobilie.address?.postCode as string) || '',
    immobilienCity: (immobilie.address?.city as string) || '',
    immobilienStreet: (immobilie.address?.street as string) || '',
    immobilienHouseNumber: (immobilie.address?.houseNumber as string) || '',
  };
}
