import { useEffect, useState } from 'react';
import {
  Immobilie,
  ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
  useUpdateEstateData,
} from '@dtk/query';
import { Button, DropdownField, FeedbackVariant, MessageBanner, validationMessages } from '@dtk/ui-components';
import { Form, Formik } from 'formik';
import { energieeffizienzFormSchema, EnergieeffizienzInfo } from './constant';
import { mapImmobilieDtoToForm, mapFormToImmobilieDto } from './utils';
import { trackFormError, trackFormStart, trackFormSuccess } from '@dtk/user-tracking';
import { AENDERUNGEN_UEBERNOMMEN_30_SEK } from '../../labels';

export interface EnergieeffizienzFormProps {
  estateValues: Immobilie | undefined;
  editable: boolean;
}

const formName = 'immobiliendaten.energieeffizenz';
export function EnergieeffizienzForm({ estateValues, editable }: EnergieeffizienzFormProps) {
  const [feedbackState, setFeedbackState] = useState({ showFeedback: false, headline: '', variant: 'success' });
  const energieeffizienzValues = mapImmobilieDtoToForm(estateValues);
  const { mutate } = useUpdateEstateData();

  let isInitalValid;
  energieeffizienzFormSchema.isValid(energieeffizienzValues).then((isValid) => {
    isInitalValid = isValid;
  });

  useEffect(() => trackFormStart(formName), []);
  const handleSubmit = (values: EnergieeffizienzInfo) => {
    mutate(
      {
        data: mapFormToImmobilieDto(values),
      },
      {
        onSuccess: async () => {
          trackFormSuccess(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: AENDERUNGEN_UEBERNOMMEN_30_SEK,
            variant: 'success',
          });
        },
        onError: () => {
          trackFormError(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: validationMessages.error.portalFormBanner.text,
            variant: 'error',
          });
        },
      }
    );
  };

  return (
    <Formik<EnergieeffizienzInfo>
      validateOnMount={true}
      isInitialValid={isInitalValid}
      initialValues={energieeffizienzValues}
      validationSchema={energieeffizienzFormSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <div data-testid="energieeffizienz">
          {feedbackState.showFeedback && (
            <div className="pb-2">
              <MessageBanner
                message={feedbackState.headline}
                isError={(feedbackState.variant as FeedbackVariant) === 'error'}
                isSuccess={(feedbackState.variant as FeedbackVariant) === 'success'}
              />
            </div>
          )}
          <div className="grid grid-cols-6 gap-x-6 gap-y-4">
            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="energieeffizienzKlasse"
                name="energieeffizienzKlasse"
                label="Energieeffizienzklasse"
                options={Object.values(ImmobilieEnergieeffizienzEnergieeffizienzKlasse)}
                disabled={!editable}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="energieeffizienzhausstandard"
                name="energieeffizienzhausstandard"
                label="Energieeffizienzhausstandard"
                options={Object.values(ImmobilieEnergieeffizienzEnergieeffizienzhausstandard)}
                disabled={!editable}
              />
            </div>
          </div>
        </div>
        {editable && (
          <Button className="mt-4 font-semibold" type="submit">
            Speichern
          </Button>
        )}
      </Form>
    </Formik>
  );
}
