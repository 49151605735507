import {
  Immobilie,
  ImmobilieModernisierung,
  ImmobilieModernisierungModernisierungBadWc,
  ImmobilieModernisierungModernisierungBodenbelaege,
  ImmobilieModernisierungModernisierungDach,
  ImmobilieModernisierungModernisierungFenster,
  ImmobilieModernisierungModernisierungHeizung,
  ImmobilieModernisierungModernisierungRaumaufteilung,
  ImmobilieModernisierungModernisierungStromWasserHeizung,
  ImmobilieModernisierungModernisierungWaermedaemmung,
} from '@dtk/query';
import { initalValuesModernisierungsDatenForm, ModernisierungDateninfo } from './constant';

export function mapModernisierungsDatenToImmobilieDto(modernisierungDaten: ModernisierungDateninfo): Immobilie {
  const moderniserungsDto: ImmobilieModernisierung = {
    modernisierungBadWc: modernisierungDaten?.baederWc as ImmobilieModernisierungModernisierungBadWc,
    modernisierungStromWasserHeizung:
      modernisierungDaten.stromWasserHeizung as ImmobilieModernisierungModernisierungStromWasserHeizung,
    modernisierungDach: modernisierungDaten.dach as ImmobilieModernisierungModernisierungDach,
    modernisierungFenster: modernisierungDaten.fenster as ImmobilieModernisierungModernisierungFenster,
    modernisierungHeizung: modernisierungDaten.heizung as ImmobilieModernisierungModernisierungHeizung,
    modernisierungRaumaufteilung:
      modernisierungDaten.raumaufteilung as ImmobilieModernisierungModernisierungRaumaufteilung,
    modernisierungWaermedaemmung:
      modernisierungDaten.waermedaemmung as ImmobilieModernisierungModernisierungWaermedaemmung,
    modernisierungBodenbelaege: modernisierungDaten.boden as ImmobilieModernisierungModernisierungBodenbelaege,
  };
  return { modernisierung: { ...moderniserungsDto } };
}

export function mapImmobilieDtoToModernisierungsForm(immobilie: Immobilie | undefined): ModernisierungDateninfo {
  if (!immobilie || !immobilie?.modernisierung) {
    return initalValuesModernisierungsDatenForm;
  }
  return {
    baederWc: immobilie.modernisierung?.modernisierungBadWc as ImmobilieModernisierungModernisierungBadWc,
    stromWasserHeizung: immobilie.modernisierung
      ?.modernisierungStromWasserHeizung as ImmobilieModernisierungModernisierungStromWasserHeizung,
    dach: immobilie.modernisierung?.modernisierungDach as ImmobilieModernisierungModernisierungDach,
    fenster: immobilie.modernisierung?.modernisierungFenster as ImmobilieModernisierungModernisierungFenster,
    heizung: immobilie.modernisierung?.modernisierungHeizung as ImmobilieModernisierungModernisierungHeizung,
    raumaufteilung: immobilie.modernisierung
      ?.modernisierungRaumaufteilung as ImmobilieModernisierungModernisierungRaumaufteilung,
    waermedaemmung: immobilie.modernisierung
      ?.modernisierungWaermedaemmung as ImmobilieModernisierungModernisierungWaermedaemmung,
    boden: immobilie.modernisierung?.modernisierungBodenbelaege as ImmobilieModernisierungModernisierungBodenbelaege,
  };
}
