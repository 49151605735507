import { useGetEstateData } from '@dtk/query';
import { CardHeader, Card } from '@dtk/ui-components';
import { EstateForms } from '../02_composite/forms';

export function EstateOverviewCard() {
  const { data: estate } = useGetEstateData();

  return (
    <div className="space-y-0">
      {!estate?.data?.isRated ? (
        <CardHeader headline="Überprüfen Sie Ihre Daten." badge="Meine Immobilie" />
      ) : (
        <CardHeader headline="Ihre Angaben zu Ihrer Immobilie" badge="Meine Immobilie" />
      )}
      <Card className="bg-coolGray-50" headline="Meine Immobilie">
        <div className="w-full md:w-2/3">
          <EstateForms editable={!estate?.data?.isRated} />
        </div>
      </Card>
    </div>
  );
}
