import { useGetEstateData, useGetEstatePricingTrend } from '@dtk/query';
import { Card, ErrorState, Info, Spinner } from '@dtk/ui-components';
import { EstateValue } from './EstateValue';

export const EstateValueCard = () => {
  const HEADLINE = 'Immobilienwert';
  const currentYear = new Date().getFullYear();

  const { data: trendData, isLoading: trendLoading, isError: trendError } = useGetEstatePricingTrend();
  const { data: estateData, isLoading: estateLoading, isError: estateError } = useGetEstateData();

  const trend = trendData?.data;
  const estate = estateData?.data;
  // actually we receive the kaufpreisMittel, 5toC is just a label
  // with ticket DP-1382 we changed the calculation based on kaufpreisMittel
  const estateValue = trend?.estate5tocValue;
  const wohnflaeche = estate?.basisdaten?.wohnflaeche;

  if (trendLoading || estateLoading) return <Spinner />;

  if (!trendData?.data) return null;

  //TODO:  Use Error Message
  if (trendError || estateError) return <ErrorState state={'noDataAvailable'} />;
  if (!trend || !estateValue || !wohnflaeche || wohnflaeche === 0) return null;

  const trendCurrentYear =
    trend.past?.find((trend) => trend.year === currentYear.toString()) ||
    trend.future?.find((trend) => trend.year === currentYear.toString());

  if (!trendCurrentYear?.relative || !trendCurrentYear?.totalValue) return null;
  const squareMeterPrice = trendCurrentYear.totalValue / wohnflaeche;

  return (
    <Card
      className="bg-white"
      fullHeight
      headline={HEADLINE}
      info={
        <Info
          variant="tooltip"
          content="Ihr Immobilienwert ist der Mittelwert der Kaufpreisspanne."
          preferedPosition="top"
        />
      }
    >
      <EstateValue
        estateValue={parseFloat(trendCurrentYear.totalValue.toFixed(0))}
        squareMeterPrice={parseFloat(squareMeterPrice.toFixed(0))}
        relative={parseFloat(trendCurrentYear.relative.toFixed(2))}
        previousYear={currentYear - 1}
        desiredAmount={estate?.finanzielleDaten?.wunschauszahlung}
        maxAmount={estate?.finanzielleDaten?.maximalauszahlung}
      />
    </Card>
  );
};
