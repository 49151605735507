import {
  ImmobilieModernisierungModernisierungBadWc,
  ImmobilieModernisierungModernisierungBodenbelaege,
  ImmobilieModernisierungModernisierungDach,
  ImmobilieModernisierungModernisierungFenster,
  ImmobilieModernisierungModernisierungHeizung,
  ImmobilieModernisierungModernisierungRaumaufteilung,
  ImmobilieModernisierungModernisierungStromWasserHeizung,
  ImmobilieModernisierungModernisierungWaermedaemmung,
} from '@dtk/query';
import { validationMessages } from '@dtk/ui-components';
import { object, mixed } from 'yup';

export interface ModernisierungDateninfo {
  baederWc: ImmobilieModernisierungModernisierungBadWc | string;
  stromWasserHeizung: ImmobilieModernisierungModernisierungStromWasserHeizung | string;
  dach: ImmobilieModernisierungModernisierungDach | string;
  fenster: ImmobilieModernisierungModernisierungFenster | string;
  heizung: ImmobilieModernisierungModernisierungHeizung | string;
  raumaufteilung: ImmobilieModernisierungModernisierungRaumaufteilung | string;
  waermedaemmung: ImmobilieModernisierungModernisierungWaermedaemmung | string;
  boden: ImmobilieModernisierungModernisierungBodenbelaege | string;
}
export const initalValuesModernisierungsDatenForm: ModernisierungDateninfo = {
  baederWc: '',
  stromWasserHeizung: '',
  dach: '',
  fenster: '',
  heizung: '',
  raumaufteilung: '',
  waermedaemmung: '',
  boden: '',
};

export const ModernisierungDatenFormSchema = object({
  baederWc: mixed<ImmobilieModernisierungModernisierungBadWc>()
    .oneOf(Object.values(ImmobilieModernisierungModernisierungBadWc))
    .required(validationMessages.error.defaultDropdownField.text),
  stromWasserHeizung: mixed<ImmobilieModernisierungModernisierungStromWasserHeizung>()
    .oneOf(Object.values(ImmobilieModernisierungModernisierungStromWasserHeizung))
    .required(validationMessages.error.defaultDropdownField.text),
  dach: mixed<ImmobilieModernisierungModernisierungDach>()
    .oneOf(Object.values(ImmobilieModernisierungModernisierungDach))
    .required(validationMessages.error.defaultDropdownField.text),
  fenster: mixed<ImmobilieModernisierungModernisierungFenster>()
    .oneOf(Object.values(ImmobilieModernisierungModernisierungFenster))
    .required(validationMessages.error.defaultDropdownField.text),
  heizung: mixed<ImmobilieModernisierungModernisierungHeizung>()
    .oneOf(Object.values(ImmobilieModernisierungModernisierungHeizung))
    .required(validationMessages.error.defaultDropdownField.text),
  raumaufteilung: mixed<ImmobilieModernisierungModernisierungRaumaufteilung>()
    .oneOf(Object.values(ImmobilieModernisierungModernisierungRaumaufteilung))
    .required(validationMessages.error.defaultDropdownField.text),
  waermedaemmung: mixed<ImmobilieModernisierungModernisierungWaermedaemmung>()
    .oneOf(Object.values(ImmobilieModernisierungModernisierungWaermedaemmung))
    .required(validationMessages.error.defaultDropdownField.text),
  boden: mixed<ImmobilieModernisierungModernisierungBodenbelaege>()
    .oneOf(Object.values(ImmobilieModernisierungModernisierungBodenbelaege))
    .required(validationMessages.error.defaultDropdownField.text),
});
