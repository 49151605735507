import {
  ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
} from '@dtk/query';
import { validationMessages } from '@dtk/ui-components';
import { object, mixed } from 'yup';

export const energieeffizienzFormSchema = object({
  energieeffizienzKlasse: mixed<ImmobilieEnergieeffizienzEnergieeffizienzKlasse>()
    .oneOf(Object.values(ImmobilieEnergieeffizienzEnergieeffizienzKlasse))
    .required(validationMessages.error.defaultInputField.promptText),
  energieeffizienzhausstandard: mixed<ImmobilieEnergieeffizienzEnergieeffizienzhausstandard>()
    .oneOf(Object.values(ImmobilieEnergieeffizienzEnergieeffizienzhausstandard))
    .required(validationMessages.error.defaultInputField.promptText),
});

export const initialValueEnergieeffizienzFrom: EnergieeffizienzInfo = {
  energieeffizienzKlasse: '',
  energieeffizienzhausstandard: '',
};

export interface EnergieeffizienzInfo {
  energieeffizienzKlasse: ImmobilieEnergieeffizienzEnergieeffizienzKlasse | string;
  energieeffizienzhausstandard: ImmobilieEnergieeffizienzEnergieeffizienzhausstandard | string;
}
