import { useEffect, useState } from 'react';
import { Immobilie, ImmobilieBauweiseBauweise, useUpdateEstateData } from '@dtk/query';
import {
  Button,
  RadioButtons,
  FeedbackVariant,
  DropdownField,
  validationMessages,
  MessageBanner,
} from '@dtk/ui-components';
import { Form, Formik } from 'formik';
import {
  bauweiseFormSchema,
  BauweiseInfo,
  dachausbauOptions,
  jaNeinRadioButtons,
  initialValueBauweiseFrom,
} from './constant';
import { mapImmobilieDtoToForm, mapFormToImmobilieDto } from './utils';
import { trackFormError, trackFormStart, trackFormSuccess } from '@dtk/user-tracking';
import { AENDERUNGEN_UEBERNOMMEN_30_SEK } from '../../labels';

export interface BauweiseFormProps {
  estateValues: Immobilie | undefined;
  editable: boolean;
}

const formName = 'immobiliendaten.bauweise';
export function BauweiseForm({ estateValues, editable }: BauweiseFormProps) {
  const [feedbackState, setFeedbackState] = useState({ showFeedback: false, headline: '', variant: 'success' });
  const bauweiseValues = mapImmobilieDtoToForm(estateValues);
  const [formDaten, setFormDaten] = useState<BauweiseInfo>(bauweiseValues || initialValueBauweiseFrom);
  const { mutate } = useUpdateEstateData();

  let isInitalValid;
  bauweiseFormSchema.isValid(bauweiseValues).then((isValid) => {
    isInitalValid = isValid;
  });

  useEffect(() => trackFormStart(formName), []);
  const handleSubmit = async (values: BauweiseInfo) => {
    mutate(
      {
        data: mapFormToImmobilieDto(values),
      },
      {
        onSuccess: async (updatedData) => {
          trackFormSuccess(formName);
          setFormDaten(mapImmobilieDtoToForm(updatedData.data as Immobilie));
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: AENDERUNGEN_UEBERNOMMEN_30_SEK,
            variant: 'success',
          });
        },
        onError: () => {
          trackFormError(formName);
          setFeedbackState({
            ...feedbackState,
            showFeedback: true,
            headline: validationMessages.error.portalFormBanner.text,
            variant: 'error',
          });
        },
      }
    );
  };

  return (
    <Formik<BauweiseInfo>
      validateOnMount={true}
      isInitialValid={isInitalValid}
      initialValues={formDaten}
      validationSchema={bauweiseFormSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      <Form>
        {feedbackState.showFeedback && (
          <div className="pb-2">
            <MessageBanner
              message={feedbackState.headline}
              isError={(feedbackState.variant as FeedbackVariant) === 'error'}
              isSuccess={(feedbackState.variant as FeedbackVariant) === 'success'}
            />
          </div>
        )}
        <div data-testid="bauweiseForm">
          <div className="grid grid-cols-6 gap-x-6 gap-y-4">
            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="bauweise"
                name="bauweise"
                label="Bauweise"
                disabled={!editable}
                options={Object.values({ '': 'Keine Angabe', ...ImmobilieBauweiseBauweise })}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <DropdownField
                aria-label="dachausbau"
                name="dachausbau"
                label="Dachausbau"
                disabled={!editable}
                options={Object.values({ '': 'Keine Angabe', ...dachausbauOptions })}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <RadioButtons {...jaNeinRadioButtons} disabled={!editable} />
            </div>
          </div>
        </div>
        {editable && (
          <Button className="mt-4 font-semibold" type="submit">
            Speichern
          </Button>
        )}
      </Form>
    </Formik>
  );
}
