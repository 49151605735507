import {
  ImmobilieAusstattungBadtyp,
  ImmobilieAusstattungAbstellraum,
  ImmobilieAusstattungFussbodenbelag,
  ImmobilieBauweiseKellerart,
  ImmobilieBauweiseKellerausbaustufe,
  ImmobilieObjektinformationenHeizungen,
  ImmobilieObjektinformationenFenster,
  ImmobilieObjektinformationenDacheindeckung,
  Immobilie,
} from '@dtk/query';
import { initialValuesFrom, AusstattungInfo, badtypOptions, fensterOptions, abstellraumOptions } from './constant';

const getKeyByValue = (object: Record<string, string>, value: string): string | undefined => {
  return Object.keys(object).find((key) => object[key] === value);
};

export function mapFormToImmobilieDto(ausstattung: AusstattungInfo): Immobilie {
  const immobilienDto: Immobilie = {
    ausstattung: {
      badtyp: getKeyByValue(badtypOptions, ausstattung.badtyp) as ImmobilieAusstattungBadtyp,
      abstellraum: getKeyByValue(abstellraumOptions, ausstattung.abstellraum) as ImmobilieAusstattungAbstellraum,
      fussbodenbelag: ausstattung.fussbodenbelag as ImmobilieAusstattungFussbodenbelag,
      balkon: (ausstattung.balkon as string) === 'ja',
      garten: (ausstattung.garten as string) === 'ja',
      zusatzBaeder: (ausstattung.zusatzBaeder as string) === 'ja',
      offenerKamin: (ausstattung.offenerKamin as string) === 'ja',
      sauna: (ausstattung.sauna as string) === 'ja',
      ueberwiegendeAufputzleitung: (ausstattung.ueberwiegendeAufputzleitung as string) === 'ja',
    },
    bauweise: {
      kellerart: ausstattung.kellerart as ImmobilieBauweiseKellerart,
      kellerausbaustufe: ausstattung.kellerausbaustufe as ImmobilieBauweiseKellerausbaustufe,
    },
    objektinformationen: {
      heizungen: ausstattung.heizung as ImmobilieObjektinformationenHeizungen,
      fenster: getKeyByValue(fensterOptions, ausstattung.fenster) as ImmobilieObjektinformationenFenster,
      dacheindeckung: ausstattung.dacheindeckung as ImmobilieObjektinformationenDacheindeckung,
    },
    stellplatz: {
      garagenstellplatz: ausstattung.garagenstellplatz as number,
      stellplatzImFreien: ausstattung.stellplatzImFreien as number,
    },
  };

  return immobilienDto;
}

export function mapImmobilieDtoToForm(immobilie: Immobilie | undefined): AusstattungInfo {
  if (!immobilie) {
    return initialValuesFrom;
  }

  const { ausstattung, bauweise, objektinformationen, stellplatz } = immobilie;

  return {
    badtyp: badtypOptions[ausstattung?.badtyp || ''] as string,
    abstellraum: abstellraumOptions[ausstattung?.abstellraum || ''] as string,
    fussbodenbelag: ausstattung?.fussbodenbelag as string,
    balkon: (ausstattung?.balkon as boolean) ? 'ja' : 'nein',
    garten: (ausstattung?.garten as boolean) ? 'ja' : 'nein',
    zusatzBaeder: (ausstattung?.zusatzBaeder as boolean) ? 'ja' : 'nein',
    offenerKamin: (ausstattung?.offenerKamin as boolean) ? 'ja' : 'nein',
    sauna: (ausstattung?.sauna as boolean) ? 'ja' : 'nein',
    ueberwiegendeAufputzleitung: (ausstattung?.ueberwiegendeAufputzleitung as boolean) ? 'ja' : 'nein',
    kellerart: bauweise?.kellerart as string,
    kellerausbaustufe: bauweise?.kellerausbaustufe as string,
    heizung: objektinformationen?.heizungen as string,
    fenster: fensterOptions[objektinformationen?.fenster || ''] as string,
    dacheindeckung: objektinformationen?.dacheindeckung as string,
    garagenstellplatz: stellplatz?.garagenstellplatz || (0 as number),
    stellplatzImFreien: stellplatz?.stellplatzImFreien || (0 as number),
  };
}
