import { StageStatus, useGetStage } from '@dtk/query';
import { Card, FaqDisclosure, QuestionAnswerData, Spinner } from '@dtk/ui-components';

export function TeilverkaufFAQCard() {
  const { data: stage, isLoading, isError } = useGetStage();

  if (isLoading) {
    return (
      <Card headline="Themenbereich / Häufige Fragen">
        <Spinner />
      </Card>
    );
  }

  if (isError || !stage?.data || !stage?.data?.status) {
    return (
      <Card headline="Themenbereich / Häufige Fragen">
        <p>Etwas ist schief gelaufen, das Support-Team arbeitet dran.</p>
      </Card>
    );
  }

  const { status } = stage.data;

  const faqs = getFaqBasedOnCustomerStage(status);
  if (!faqs) {
    return null;
  }

  return (
    <Card headline="Themenbereich / FAQs">
      <FaqDisclosure questionAnswerData={faqs} />
    </Card>
  );
}

export const getFaqBasedOnCustomerStage = (status: StageStatus): QuestionAnswerData[] | null => {
  const faqStatusMap: Record<string, QuestionAnswerData[]> = {
    [StageStatus.Initial]: [
      {
        question: 'Für wen eignet sich ein Immobilien-Teilverkauf?',
        answer:
          'Prinzipiell kommt jeder Immobilienbesitzer für einen Teilverkauf infrage, der aus seiner Immobilie Liquidität schöpfen will.\n Der Fokus unseres Angebots liegt auf Immobilienbesitzern in der Altersgruppe ab 55 Jahren.\n Vor allem hohe Ausgaben für die eigene Gesundheit und Pflege oder aber ein altersgerechter Umbau der eigenen Immobilie können in diesem Alter zur finanziellen Belastung werden.\n Insbesondere Senioren möchten im Alter keine Kredite mehr aufnehmen.\n Unser Angebot bietet Eigentümern die Möglichkeit, das in der Immobilie gebundene Kapital in Geld umzuwandeln und zugleich die Entscheidungshoheit über die Immobilie zu behalten.\n Somit kann eine finanzielle Unabhängigkeit im Alter gesichert werden, ohne die Immobilie zu verlieren.\n Der Teilverkauf bietet eine attraktive Alternative zur Leibrente oder zum Hypothekendarlehen.',
      },
      {
        question: 'Wie lange dauert es vom Angebot bis zur Auszahlung der Wunschsumme?',
        answer:
          'In der Regel erhalten Sie die gewünschte Auszahlungssumme innerhalb von einigen Wochen, nachdem Sie Ihr Angebot angefordert haben.\n Sie können die Zeit vom Angebot bis zum Notartermin verkürzen, indem Sie zum Beispiel notwendige Objektunterlagen zeitnah bereitstellen.\n Nach der notariellen Beurkundung müssen, wie bei jedem Grundstücksverkauf auch, bestimmte Voraussetzungen erfüllt sein.\n Dieser Zeitraum ist von den Beteiligten leider nicht zu beeinflussen.\n Denn bei diesen Voraussetzungen handelt es sich beispielsweise um Genehmigungen der Gemeinde oder Löschungsbewilligungen eingetragener Belastungen.',
      },
      {
        question: 'Profitiere ich von zukünftigen Wertsteigerungen?',
        answer:
          'Selbstverständlich.\n Da Sie neben der Deutschen Teilkauf weiter Miteigentümer der Immobilie bleiben, profitieren Sie auch von künftigen Wertsteigerungen der Immobilie.',
      },
      {
        question: 'Wie läuft der Teilverkauf im Detail ab?',
        answer:
          'Nach Angabe der wichtigsten Informationen zur Immobilie sowie des Wunschauszahlungsbetrags und der Anforderung eines Angebots über die Website der Deutschen Teilkauf erhalten Sie ein unverbindliches Angebot kostenfrei von uns.\n Entscheiden Sie sich anschließend dafür, dass Sie den Teilverkauf durchführen möchten, erfolgt im nächsten Schritt eine ausführliche Beratung sowie eine Bewertung Ihrer Immobilie durch einen unabhängigen und zertifizierten Gutachter.\n Die Deutsche Teilkauf schlägt Ihnen zwei unabhängige und zertifizierte Gutachterverbände vor, zwischen denen Sie sich entscheiden können.\n Nach der Angebotsbestätigung auf Grundlage dieser Begutachtung folgen der Notartermin, der Vollzug des Kaufvertrags durch den Notar und die Auszahlung des Wunschbetrags.',
      },
    ],
    [StageStatus.unverbindliches_Angebot]: [
      {
        question: 'Muss ich ein Durchführungsentgelt zahlen?',
        answer:
          'Das Durchführungsentgelt ist eine Gebühr in Höhe von 3,25 bis 5,5 %, welche andere Anbieter im Rahmen des Gesamtverkaufs einer Immobilie erheben.\n Nur die Deutsche Teilkauf verzichtet vollumfänglich auf das Durchführungsentgelt und kompensiert dieses an keiner anderen Stelle im Gesamtprozess.\n Hiermit hebt sich die Deutsche Teilkauf entscheidend vom Wettbewerb ab.',
      },
      {
        question: 'Was ist das Nießbrauchrecht?',
        answer:
          'Im Kern handelt es sich bei dem aus dem römischen Recht stammenden Nießbrauch um ein grundbuchlich gesichertes Recht, das Ihnen das alleinige Nutzungsrecht an der gesamten Immobilie zuweist (vgl. § 1030 BGB). Bei uns ist das Nießbrauchrecht zusätzlich durch unsere Sicherheitsgarantie abgesichert. Auch hieran erkennen Sie, dass Sie der Deutschen Teilkauf vertrauen können.',
      },
      {
        question: 'Kann ich den verkauften Anteil meiner Immobilie zurückerwerben?',
        answer:
          'Ja. Sie können den verkauften Eigentumsanteil jederzeit zurückkaufen.\n Die Immobilie wird für den Rückkauf erneut von einem unabhängigen, zertifizierten Sachverständigen begutachtet, um den neuen Marktwert zu ermitteln.\n Selbstverständlich haben auch Ihre Erben ein Erstankaufsrecht auf den verkauften Anteil.',
      },
      {
        question: 'Wie lange bin ich vertraglich an die Deutsche Teilkauf gebunden?',
        answer:
          'Im Rahmen des Teilverkaufs gehen Sie keine definierte Mindestvertragslaufzeit ein.\n Im Gegenteil, Sie können den zu Ihren Gunsten eingeräumten Nießbrauch jederzeit aufgeben und damit z.B.\n einen Gesamtverkauf der Immobilie einleiten oder den verkauften Anteil zu den vereinbarten Konditionen zurückkaufen.',
      },
    ],
    [StageStatus.Gutachten]: [
      {
        question: 'Was ist ein Wertgutachten?',
        answer:
          'Ein Wertgutachten ist eine von einem Sachverständigen durchgeführte Überprüfung des Werts einer Immobilie, unter anderem anhand des Zustands, der Lage und der Marktumgebung des Objekts.',
      },
      {
        question: 'Kann ich sicher sein, dass der Gutachter unabhängig und fair arbeitet?',
        answer:
          'Die Deutsche Teilkauf schlägt Ihnen zwei unabhängige und zertifizierte Gutachterverbände vor (Dekra ab Zertifizierungsstufe D3, Sprengnetter, HypZert- oder IHK-zertifiziert, nach ISO 17024 ausgebildet (mit einer Mindestdauer von 100 Stunden)).\n Zwischen diesen Gutachterverbänden können Sie wählen, welcher die Bewertung der Immobilie vornimmt.\n Durch die Zertifizierung können Sie sicher sein, einen unabhängigen und bestens qualifizierten Gutachter für Ihre Immobilie zu bekommen.',
      },
      {
        question: 'Wer trägt die Kosten für das Wertgutachten?',
        answer: 'Die Deutsche Teilkauf übernimmt alle Kosten für das erste Wertgutachten einer Immobilie.',
      },
      {
        question: 'Wie lange dauert die Erstellung eines Wertgutachtens?',
        answer:
          'Nach Erhalt aller Unterlagen benötigt der Gutachter ca. drei Wochen für die Erstellung des Gutachtens.',
      },
      {
        question: 'Wie sicher ist die Kaufpreiszahlung durch die Deutsche Teilkauf?',
        answer:
          'Der Immobilienanteil geht erst nach Kaufpreiszahlung in den Besitz der Deutschen Teilkauf über.\n Damit haben Sie die maximale Sicherheit, dass die Zahlung zeitnah nach Vertragsunterschrift erfolgt.',
      },
    ],
    [StageStatus.verbindliches_Angebot]: [
      {
        question: 'Wie sicher ist die Kaufpreiszahlung durch die Deutsche Teilkauf?',
        answer:
          'Der Immobilienanteil geht erst nach Kaufpreiszahlung in den Besitz der Deutschen Teilkauf über.\n Damit haben Sie die maximale Sicherheit, dass die Zahlung zeitnah nach Vertragsunterschrift erfolgt.',
      },
      {
        question: 'Gibt es eine Garantie, dass ich auch weiterhin allein über meine Immobilie entscheiden kann?',
        answer:
          'Im Rahmen des Immobilien-Teilverkaufs wird als Teil des notariellen Kaufvertrags eine Miteigentümervereinbarung beurkundet.\n Diese stellt Ihre vereinbarten Rechte an der Immobilie sicher.\n Zusätzlich wird ein Nießbrauchrecht zu Ihren Gunsten am gesamten Grundbesitz und im ersten Rang des Grundbuchs eingetragen.\n Damit steht Ihnen ein lebenslanges Wohn- und Nutzungsrecht zu.',
      },
      {
        question: 'Wie lange bin ich vertraglich an die Deutsche Teilkauf gebunden?',
        answer:
          'Im Rahmen des Teilverkaufs gehen Sie keine definierte Mindestvertragslaufzeit ein.\n Im Gegenteil, Sie können den zu Ihren Gunsten eingeräumten Nießbrauch jederzeit aufgeben und damit zum Beispiel einen Gesamtverkauf der Immobilie einleiten oder den verkauften Anteil zu den vereinbarten Konditionen zurückkaufen.',
      },
    ],
    [StageStatus.Kaufvertrag]: [
      {
        question: 'Müssen meine Erben nach meinem Ableben weiter ein Nutzungsentgelt zahlen?',
        answer:
          'Die Verpflichtungen bezüglich des Nutzungsentgelts enden spätestens drei Monate nach Ihrem Ableben.\n Somit wird verhindert, dass Ihre Erben finanziell belastet werden, obwohl sie das Eigentum nicht nutzen können.',
      },
      {
        question: 'Ist der Notar beim Teilverkauf neutral?',
        answer:
          'Notare sind nicht Vertreter einer Partei, sondern unabhängige und unparteiische Betreuer aller Beteiligten.\n Sie bieten ihnen Rat und Mitwirkung an.\n Den Beteiligten steht es dabei frei, ob sie den Rat annehmen oder nicht.\n Die notarielle Urkunde – mit Brief und Siegel – ist der Inbegriff für Rechtssicherheit.\n Auf Basis der Parteivorstellungen wirkt der Notar auf eine Einigung im Sinne eines freiwilligen Interessenausgleichs hin.',
      },
      {
        question: 'Warum zahle ich ein monatliches Nutzungsentgelt?',
        answer:
          'Die Deutsche Teilkauf wird den erworbenen Miteigentumsanteil an der Immobilie dauerhaft nicht nutzen und erhält dafür eine Kompensationszahlung in Form des Nutzungsentgelts.',
      },
      {
        question: 'Was ist, wenn ich etwas an der Immobile verändern will?',
        answer:
          'Die Deutsche Teilkauf ist nach dem Teilverkauf lediglich stiller Miteigentümer an der Immobilie.\n Somit können Sie weiterhin selbst entscheiden, ob Sie zum Beispiel Ihre Immobilie renovieren.\n Die Kosten dafür tragen Sie selbst.',
      },
    ],
    [StageStatus.Angebot_angenommen]: [
      {
        question: 'Wie sieht der Kontakt mit der Deutschen Teilkauf nach dem Teilverkauf aus?',
        answer:
          'Das entscheiden Sie allein.\n Wir stehen Ihnen jederzeit für alle Fragen rund um Ihr Objekt und den Teilverkauf zur Verfügung.\n Wir führen selbstverständlich weder Kontrollbesuche noch sonstige Einschränkungen durch - Sie behalten auch nach dem Teilverkauf die Entscheidungshoheit über Ihr Eigenheim.',
      },
      {
        question: 'Kann ich den verkauften Anteil meiner Immobilie zurückerwerben?',
        answer:
          'Ja, Sie können den verkauften Miteigentumsanteil jederzeit zurückkaufen.\n Zudem erhalten Ihre Erben ein Erstankaufsrecht auf den verkauften Immobilienanteil.',
      },
      {
        question: 'Ich kann meinen Verpflichtungen aus dem Vertrag nicht mehr nachkommen. Was nun?',
        answer:
          ' Sollte bei Ihnen absehbar sein, dass Sie Verpflichtungen aus dem notariellen Kaufvertrag nicht mehr erfüllen können, suchen Sie bitte so schnell wie möglich den Kontakt zur Deutschen Teilkauf.\n Wir arbeiten dann gemeinsam an einer Lösung.\n Im Falle einer finanziellen Notlage sind wir zudem der einzige Teilkauf-Anbieter, der durch die eigens dafür gegründete Stiftung Hilfskasse unbürokratisch weiterhelfen kann.\n Der Stiftungsrat entscheidet hierbei völlig unabhängig von der Deutschen Teilkauf.',
      },
    ],
  };

  const faqs = faqStatusMap[status];
  if (!faqs || faqs.length === 0) {
    return null;
  }

  return faqs;
};
