import {
  Immobilie,
  ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
  ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
} from '@dtk/query';
import { EnergieeffizienzInfo, initialValueEnergieeffizienzFrom } from './constant';

export function mapFormToImmobilieDto(energieeffizienz: EnergieeffizienzInfo): Immobilie {
  const immobilienDto: Immobilie = {
    energieeffizienz: {
      energieeffizienzKlasse:
        energieeffizienz.energieeffizienzKlasse as ImmobilieEnergieeffizienzEnergieeffizienzKlasse,
      energieeffizienzhausstandard:
        energieeffizienz.energieeffizienzhausstandard as ImmobilieEnergieeffizienzEnergieeffizienzhausstandard,
    },
  };

  return immobilienDto;
}

export function mapImmobilieDtoToForm(immobilie: Immobilie | undefined): EnergieeffizienzInfo {
  if (!immobilie) {
    return initialValueEnergieeffizienzFrom;
  }
  return {
    energieeffizienzKlasse: immobilie.energieeffizienz?.energieeffizienzKlasse as string,
    energieeffizienzhausstandard: immobilie.energieeffizienz?.energieeffizienzhausstandard as string,
  };
}
