import {
  MAXIMUM_REAL_ESTATE_VALUE,
  MINIMUM_EXISTING_LOADS_AMOUNT,
  MINIMUM_REAL_ESTATE_VALUE,
  MINIMUM_REQUESTED_PAYOUT,
  RadioButtonProps,
  validationMessages,
} from '@dtk/ui-components';
import { object, number } from 'yup';

export interface FinanzielleDatenInfo {
  geschaetzterWert: number | string;
  wunschWert: number | string;
  bestehendeLasten: 'nein' | 'ja';
  bestehendeLastenBetrag: number | string;
}

export const initalValuesFinazielleDatenForm: FinanzielleDatenInfo = {
  geschaetzterWert: '',
  wunschWert: '',
  bestehendeLasten: 'nein',
  bestehendeLastenBetrag: '',
};

export const FinazielleDatenFormSchema = object({
  geschaetzterWert: number()
    .min(MINIMUM_REAL_ESTATE_VALUE, validationMessages.error.realEstateValue.min.text)
    .max(MAXIMUM_REAL_ESTATE_VALUE, validationMessages.error.realEstateValue.max.text)
    .typeError(validationMessages.error.defaultInputField.promptText)
    .required(validationMessages.error.defaultInputField.promptText),
  wunschWert: number()
    .min(MINIMUM_REQUESTED_PAYOUT, validationMessages.error.requestedPayout.min.text)
    .typeError(validationMessages.error.defaultInputField.promptText)
    .required(validationMessages.error.defaultInputField.promptText)
    .test({
      name: 'max',
      exclusive: false,
      message: validationMessages.error.requestedPayout.max.text,
      test: function (value) {
        return (value || 0) <= parseInt(this.parent.geschaetzterWert) / 2;
      },
    }),
  bestehendeLastenBetrag: number().when('bestehendeLasten', {
    is: 'ja',
    then: number()
      .min(MINIMUM_EXISTING_LOADS_AMOUNT, validationMessages.error.existingLoadsAmount.min.text)
      .typeError(validationMessages.error.defaultInputField.promptText)
      .required(validationMessages.error.defaultInputField.promptText)
      .test({
        name: 'max',
        exclusive: true,
        message: validationMessages.error.existingLoadsAmount.max.text,
        test: function (value) {
          return (value || 0) <= parseInt(this.parent.wunschWert);
        },
      }),
    otherwise: number().typeError(validationMessages.error.defaultInputField.promptText),
  }),
});

export const jaNeinRadioButtons: RadioButtonProps = {
  name: 'bestehendeLasten',
  headline: 'Bestehende Lasten',
  buttons: [
    { id: 'nein', title: 'Nein' },
    { id: 'ja', title: 'Ja' },
  ],
};
