import { useGetEstateData, useGetEstatePricingTrend } from '@dtk/query';
import { Card, CardSize, QueryStatusCard, Spinner } from '@dtk/ui-components';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { PricingTrendChart } from './PricingTrendChart';
import { DisplayTrend, calculateNumTicksAxisBottom, mapToChartData } from './utils';

export interface EstatePricingTrendChartProps {
  trends: DisplayTrend[];
}

export const PricingTrendChartCard = () => {
  const { data: trendData, isLoading: trendLoading, isError: trendError } = useGetEstatePricingTrend();
  const { data: estateData, isLoading: estateLoading, isError: estateError } = useGetEstateData();
  const cardTitle = 'Marktpreisentwicklung';

  if (trendLoading || estateLoading) {
    return <Spinner />;
  }

  if (trendError || estateError) {
    return <QueryStatusCard title={cardTitle} state="noDataAvailable" cardSize={CardSize.SMALL} />;
  }

  if (!trendData?.data) {
    return <QueryStatusCard title={cardTitle} state="noDataAvailable" cardSize={CardSize.SMALL} />;
  }

  const wohnflaeche = estateData?.data.basisdaten?.wohnflaeche;
  const address = estateData?.data.address;
  const trends = trendData.data;

  if (JSON.stringify(trends) === '{}' || !wohnflaeche) {
    return <QueryStatusCard title={cardTitle} state="noDataAvailable" cardSize={CardSize.SMALL} />;
  }

  const trendsWithoutDuplicates = {
    ...trends,
    future: trends.future?.slice(1),
  };

  return (
    <Card headline={cardTitle} subline="Der durchschnittliche Immobilienpreis für Häuser zum Kauf">
      <ParentSize>
        {({ width }) => (
          <PricingTrendChart
            height={450}
            width={width}
            street={address?.street}
            trends={mapToChartData(trendsWithoutDuplicates, wohnflaeche)}
            calculateNumTicksAxisBottom={calculateNumTicksAxisBottom}
          />
        )}
      </ParentSize>
    </Card>
  );
};
